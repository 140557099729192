<template>
  <div id="wizard" v-if="viewData">
      <form-wizard title="" subtitle="" :lazy="true" @on-validate="validate" ref="wizard" color="var(--primary)"
      shape="square">
        <tab-content title="Categoría" class="col-sm-12">
          <div class="row">
            <div class="col-sm-12">
              <select-category title="Elige una categoría" :cards="cardsParent" :selected.sync="categorySelected"/>
            </div>
          </div>
        </tab-content>
        <tab-content title="Variable" class="col-sm-12">
          <div class="row horizontal-center">
            <div class="col-sm-12">
              <select-category title="Elige una variable" :cards="cards.filter(card => categorySelected && card.parent === categorySelected.id)" :selected.sync="itemSelected" textButton="Seleccionar variables"/>
            </div>
          </div>
        </tab-content>
        <tab-content title="Grupo" :before-change="validateVariable" class="col-sm-12">
          <h3>Definir el grupo</h3>
          <div class="col-sm-12 ">
              <form-group @send="onAccept" :key="keyFormRender" :item="itemSelected" origin="group" ref="formGroup"></form-group>
          </div>
        </tab-content>
        <template slot="footer" slot-scope="props">
          <div class="col-sm-6 text-danger text-right" v-if="errorMsg && errorMsg !== ''">{{errorMsg}}</div>
          <div class="wizard-footer-left to-left-card col-sm-6 mb-1" v-if="props.activeTabIndex > 0">
            <b-button @click="props.prevTab()" variant="outline-secondary">Atrás</b-button>
          </div>
          <div class="wizard-footer-right col-sm-6 mb-1" v-if="props.isLastStep">
            <b-button class="push-right" @click="$refs.formGroup.callSubmitFormRender()" variant="warning">Crear grupo</b-button>
            <!-- <vs-button v-if="!props.isLastStep" @click.native="props.nextTab()" color="primary">Continuar</vs-button> -->
          </div>
        </template>
      </form-wizard>
  </div>
</template>
<script>
import { FormWizard, TabContent } from 'vue-form-wizard'
import 'vue-form-wizard/dist/vue-form-wizard.min.css'
import SelectCategory from './SelectCategory'
import FormGroup from './FormGroup'

import TicketManagementService from '../../ticketManagement.service.js'

// import cards from '@/const-data/groups/cards'
import relationTypeItems from '@/const-data/groups/relation-type-item.js'

export default {
  components: {SelectCategory, FormGroup, FormWizard, TabContent},
  data () {
    return {
      categorySelected: null,
      itemSelected: null,
      cards: [],
      cardsParent: [],
      errorMsg: '',

      buttonSend: {text: 'Crear grupo', icon: '', color: 'success', class: 'push-right'},
      groupField: [
        {fieldType: 'FieldInput', name: 'group_name', label: 'Nombre grupo', containerClass: 'container-info col-sm-12', validation: 'required|length:6,1000,El texto debe tener entre 6 y 1000 caracteres'},
        {fieldType: 'FieldTextarea', placeholder: '', name: 'group_desc', label: 'Descripción del grupo', containerClass: 'container-info col-sm-12', validation: 'required|length:6,1000,El texto debe tener entre 6 y 1000 caracteres', maxLength: 1000}
      ],
      fields: [],
      form: {},
      keyFormRender: 0,
      totalInputs: 1,
      totalActiveInputs: 1,
      chips: [],
      viewData: false,
      ticketManagementService: new TicketManagementService(this)
    }
  },
  watch: {
    categorySelected () {
      this.$refs.wizard.changeTab(0, 1)
    },
    itemSelected () {
      this.fields = []
      this.form = {}
      this.totalInputs = 1
      this.totalActiveInputs = 1
      this.keyFormRender++
      this.$refs.wizard.changeTab(1, 2)
    }
  },
  mounted () {
    this.getConditions()
  },
  methods: {
    async getConditions() {
      const arrServices = [
        {name: 'getConditions'}
      ]
      this.ticketManagementService.callMultipleServices(arrServices, true)
        .then(response => {
          this.cards = response.getConditions.data
          this.cardsParent = this.cards.filter(card => !card.parent)
          this.viewData = true
        })
        .catch(err => {
          console.error(err)
        })
    },
    validateVariable (e) {
      this.errorMsg = 'Debe seleccionar una opción'
      return false
    },
    validate (isValid, tabIndex) {
      if (isValid) {
        this.errorMsg = null
      }
    },
    onAccept (form) {
      const payload = {
        variable: this.itemSelected.id,
        type: relationTypeItems[this.itemSelected.type].type,
        values: form.values,
        name: form.group_name,
        description: form.group_desc
      }
      this.ticketManagementService.callService('saveGroup', payload)
        .then(response => {
          if (response.data.insertId) {
            this.$success('El grupo se ha guardado correctamente')
            this.$refs.wizard.reset()
            this.form = {}
            this.fields = []
          }
        })
        .catch(err => {
          console.error(err)
          this.$alert(this.$t('msg-problema-guardar', {code: err}))
        })
    }
  }
}
</script>
<style lang="scss">
  @import '@core/scss/vue/libs/vue-wizard.scss';
</style>